<template>
  <section
    class="payment-gateway-config"
    :class="{ 'scroll-content': !store_setup_import }"
  >
    <h3
      :class="{
        'md-headline': store_setup_import,
        'md-display-1': !store_setup_import
      }"
    >
      {{ $translate("payment_gateway_config.title") }}:
    </h3>

    <div
      class="payment-gateway-config__config md-layout md-gutter md-alignment-top"
    >
      <div
        class="md-layout-item md-size-50 md-small-size-100"
      >
        <div class="payment-gateway-config__config-item md-elevation-3">
          <section_description_preview>
            <template v-slot:title>
              <div class="md-body-2">
                {{ $translate("payment_gateway_config.message_paypal_title") }}
              </div>
            </template>
            <div class="md-body-1">
              {{ $translate("payment_gateway_config.message_paypal") }}
            </div>
          </section_description_preview>

          <div class="payment-gateway-config__checkbox">
            <paypal_icon />
            <md-checkbox
              v-model="use_paypal_gateway"
              :disabled="(
                !is_paypal_filled_out ||
                !is_paypal_connected ||
                !can_user_manage_configuration
              )"
            >
              {{ $translate("payment_gateway_config.use_paypal") }}
            </md-checkbox>
          </div>
          <hr>
          <section_description_preview>
            <template v-slot:title>
              <div class="md-body-2">
                {{ $translate("payment_gateway_config.message_shopify_title") }}
              </div>
            </template>
            <div class="md-body-1">
              {{ $translate("payment_gateway_config.message_shopify") }}
            </div>
          </section_description_preview>
          
          <div class="payment-gateway-config__checkbox">
            <md-tooltip md-direction="top">{{
              $translate("payment_gateway_config.shopify_not_connected")
            }}</md-tooltip>
            <shopify_icon />
            <md-checkbox
              v-model="use_shopify_gateway"
              :disabled="!is_shopify_connected || !can_user_manage_configuration"
            >
              {{ $translate("payment_gateway_config.use_shopify") }}
            </md-checkbox>
          </div>
        </div>
      </div>

      <div class="md-layout-item md-size-50 md-small-size-100">
        <div class="payment-gateway-config__config-item md-elevation-3">
          <h3 class="md-headline">
            {{ $translate("payment_gateway_config.paypal_config_title") }}:
          </h3>
          <p class="md-warning md-alert--box"><b>{{ $translate("payment_gateway_config.necessary_paypal_config") }}</b></p>

          <div class="payment-gateway-config__key">
            <div class="md-title">
              {{ $translate(`payment_gateway_config.values.email.title`) }}:
            </div>
            <p class="md-body-1">
              {{ $translate(`payment_gateway_config.values.email.message`) }}
            </p>
            <md-field>
              <label>{{
                $translate(`payment_gateway_config.values.email.title`)
              }}</label>
              <md-input
                :disabled="!can_user_manage_configuration"
                :value="paypal_config.email"
                @change="event => update_config('email', event.target.value, 'paypal')"
              />
            </md-field>
          </div>

          <div
            v-for="[key, val] in paypal"
            :key="`seo-config-${key}`"
            class="payment-gateway-config__key"
          >
            <div class="md-title">
              {{ $translate(`payment_gateway_config.values.${key}.title`) }}:
            </div>
            <p class="md-body-1">
              {{ $translate(`payment_gateway_config.values.${key}.message`) }}
              <a
                href="https://developer.paypal.com/developer/applications/create?env=live"
                target="_blank"
              >
                {{
                  $translate(`payment_gateway_config.values.${key}.creation_link`)
                }}
              </a>
            </p>
            <md-field>
              <label>{{
                $translate(`payment_gateway_config.values.${key}.title`)
              }}</label>
              <md-input
                :disabled="!can_user_manage_configuration"
                :value="val"
                @change="event => update_config(key, event.target.value, 'paypal')"
              />
            </md-field>
          </div>
        </div>
      </div>

      <div class="md-layout-item md-size-100">
        <div class="payment-gateway-config__config-item md-elevation-3">
          <h3 class="md-headline">
            {{ $translate("payment_gateway_config.other_payment_options.title") }}:
          </h3>
          <p class="md-body-1">
            {{ $translate("payment_gateway_config.other_payment_options.message") }}
          </p>

          <span>
            <md-tooltip v-if="!enable_bank_transfer" md-direction="top">{{
              $translate("payment_gateway_config.other_payment_options.bank_transfer_tooltip")
            }}</md-tooltip>
            <md-checkbox
              v-model="use_bank_transfer"
              :disabled="!can_user_manage_configuration || !enable_bank_transfer"
            >
              {{ $translate("payment_gateway_config.other_payment_options.options.bank_transfer") }}
            </md-checkbox>
          </span>
          <md-checkbox
            v-model="use_cash_on_pickup"
            :disabled="!can_user_manage_configuration"
            >{{ $translate("payment_gateway_config.other_payment_options.options.cash_on_pickup") }}</md-checkbox
          >
        </div>
      </div>
    </div>

    <md-snackbar
      class="payment-gateway-config__message"
      md-position="center"
      :md-active="(
        (
          checking_paypal_connection ||
          (!is_paypal_connected && !guide_data && !store_setup_import)
        ) && is_paypal_filled_out
      )"
      md-persistent
      :class="{ 'md-alert': !checking_paypal_connection }"
    >
      {{
        $translate(
          `payment_gateway_config.${
            checking_paypal_connection &&
            paypal_config.client_id &&
            paypal_config.client_secret
              ? "checking_connection"
              : "paypal_not_connected"
          }`
        )
      }}
    </md-snackbar>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
import {
  ADMIN,
  CONFIGURATION_STORE,
  severities
} from "../../../constants/others_constants"
import {
  CONFIGURATION_DATA_UPDATED,
  UPDATE_SMALL_LOADER,
  ADD_GLOBAL_ERROR
} from "../../../stores/Admin/constants"
import {
  UPDATE_PAYPAL_CONFIG,
  UPDATE_PROJECT_CONFIG,
  GET_SHOPIFY_CONNECTION_STATE
} from "../../../stores/Admin/configuration/constants"
import empty_paypal_config from "../../../constants/empty_objects/empty_paypal_config"
import paypal_icon from "../../../../Shared/components/icon_components/paypal-icon"
import shopify_icon from "../../../../Shared/components/icon_components/shopify-icon"
import paypal_connection_check from "../../../methods/paypal_connection_check"
import { check_paypal_payout_connection } from "../../../constants/endpoints/other"
import { message_durations } from "../../../../Shared/constants/other"
import section_description_preview from "../section_description_preview"

export default {
  components: {
    paypal_icon,
    shopify_icon,
    section_description_preview
  },
  props: {
    can_user_manage_configuration: {
      type: Boolean,
      required: true
    },
    store_setup_import: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      is_shopify_connected: false,
      is_paypal_connected: false,
      checking_paypal_connection: false
    };
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, [
      "paypal_config",
      "project_config",
      "shipping_options"
    ]),
    ...mapState(ADMIN, ["guide_data"]),
    paypal() {
      const config = {
        ...empty_paypal_config,
        ...this.paypal_config
      };

      return Object.entries({
        client_id: config.client_id,
        client_secret: config.client_secret
      });
    },
    is_paypal_filled_out() {
      return (
        !!this.paypal_config.client_id && !!this.paypal_config.client_secret
      );
    },
    use_paypal_gateway: {
      set(val) {
        if (val) {
          this.update_project_config(["use_paypal_payment_gateway", true]);
          this.configuration_data_updated({ types_updated: ["paypal_config", "project_config"] });
        }
      },
      get() {
        return this.project_config.use_paypal_payment_gateway;
      }
    },
    use_bank_transfer: {
      get() {
        return this.project_config.use_bank_transfer;
      },
      set(val) {
        if (typeof val === "boolean") {
          this.update_project_config(["use_bank_transfer", val]);
          this.configuration_data_updated({ types_updated: ["project_config"] });
        }
      }
    },
    use_cash_on_pickup: {
      get() {
        return this.project_config.use_cash_on_pickup;
      },
      set(val) {
        if (typeof val === "boolean") {
          this.update_project_config(["use_cash_on_pickup", val]);
          this.configuration_data_updated({ types_updated: ["project_config"] });
        }
      }
    },
    use_shopify_gateway: {
      set(val) {
        if (val) {
          this.update_project_config(["use_paypal_payment_gateway", false]);
          this.configuration_data_updated({ types_updated: ["paypal_config", "project_config"] });
        }
      },
      get() {
        return this.project_config.use_paypal_payment_gateway === false;
      }
    },
    enable_bank_transfer() {
      return this.shipping_options?.project_address?.bank_account &&
        this.shipping_options?.project_address?.iban &&
        this.shipping_options?.project_address?.swift
    }
  },
  watch: {
    is_paypal_filled_out(val) {
      this.$emit("is_filled_out", val);
    }
  },
  async mounted() {
    if (this.is_paypal_filled_out && !this.store_setup_import) {
      this.update_small_loader(true);
      this.checking_paypal_connection = true;
      await this.check_paypal_connection();
      this.is_shopify_connected = await this.get_shopify_connection_state();
      this.update_small_loader(false);
    }

    this.$emit("is_filled_out", this.is_paypal_filled_out);
  },
  methods: {
    ...mapMutations(CONFIGURATION_STORE, {
      update_paypal_config: UPDATE_PAYPAL_CONFIG,
      update_project_config: UPDATE_PROJECT_CONFIG
    }),
    ...mapMutations(ADMIN, {
      configuration_data_updated: CONFIGURATION_DATA_UPDATED,
      update_small_loader: UPDATE_SMALL_LOADER,
      add_global_message: ADD_GLOBAL_ERROR
    }),
    ...mapActions(CONFIGURATION_STORE, {
      get_shopify_connection_state: GET_SHOPIFY_CONNECTION_STATE
    }),
    async check_paypal_connection(loading) {
      if (loading) this.update_small_loader(true)

      this.checking_paypal_connection = true
      this.is_paypal_connected = await paypal_connection_check(this.paypal_config.client_id)
      const { data: payout_data } = await check_paypal_payout_connection(this.paypal_config)
      this.checking_paypal_connection = false

      if (!payout_data) this.add_global_message({
        severity: severities.WARNING,
        message: "payouts_not_enabled",
        duration: message_durations.long
      })
      if (loading) this.update_small_loader(false)
    },
    async update_config(key, value, type) {
      this[`update_${type}_config`]([key, value]);
      this.configuration_data_updated({ types_updated: ["paypal_config", "project_config"] });
      if (!this.is_paypal_filled_out && this.use_paypal_gateway)
        this.update_project_config(["use_paypal_payment_gateway", false]);
      if (this.is_paypal_filled_out) await this.check_paypal_connection(true);
      if (!this.is_shopify_connected)
        this.update_project_config(["use_paypal_payment_gateway", null]);
    }
  }
};
</script>

<style lang="scss">
@use "../../../../../styles/_admin-constants" as *;

.payment-gateway-config {
  .md-field {
    width: auto !important;
    max-width: 500px !important;
  }

  &__checkbox {
    margin-bottom: $default-size;

    svg {
      width: $double-default-size;
      height: $double-default-size;
      margin-right: $half-default-size;
      vertical-align: top;
    }

    &:last-child {
      display: inline-block;
    }
  }

  &__key .md-body-1 {
    margin-bottom: 0;
  }

  &__config {
    margin: 0 !important;
    align-items: normal;
    align-content: flex-start;

    &-item {
      padding: $half-default-size $default-size;
      margin: 0 0 $default-size;
    }
  }
}

.md-tooltip {
  height: auto;
  white-space: normal;
}
</style>
